import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  //{ path: '', loadChildren: './pages/pumpetype-list/pumpetype-list.module#PumpetypeListPageModule' },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: './home/home.module#HomePageModule' },
  { path: 'pumpetyper', loadChildren: './pages/pumpetype-list/pumpetype-list.module#PumpetypeListPageModule' },
  { path: 'pumpetype', loadChildren: './pages/pumpetype-details/pumpetype-details.module#PumpetypeDetailsPageModule' },
  { path: 'pumpetype/:id', loadChildren: './pages/pumpetype-details/pumpetype-details.module#PumpetypeDetailsPageModule' },
  { path: 'slanger', loadChildren: './pages/slange-list/slange-list.module#SlangeListPageModule' },
  { path: 'slange', loadChildren: './pages/slange-details/slange-details.module#SlangeDetailsPageModule' },
  { path: 'slange/:id', loadChildren: './pages/slange-details/slange-details.module#SlangeDetailsPageModule' },
  { path: 'sikkerheder', loadChildren: './pages/sikkerhed-list/sikkerhed-list.module#SikkerhedListPageModule' },
  { path: 'sikkerhed', loadChildren: './pages/sikkerhed-details/sikkerhed-details.module#SikkerhedDetailsPageModule' },
  { path: 'sikkerhed/:id', loadChildren: './pages/sikkerhed-details/sikkerhed-details.module#SikkerhedDetailsPageModule' },
  { path: 'priser', loadChildren: './pages/priser-list/priser-list.module#PriserListPageModule' },
  { path: 'pris', loadChildren: './pages/priser-details/priser-details.module#PriserDetailsPageModule' },
  { path: 'pris/:id', loadChildren: './pages/priser-details/priser-details.module#PriserDetailsPageModule' },
  { path: 'faq', loadChildren: './pages/faq-list/faq-list.module#FaqListPageModule' },
  { path: 'faqer', loadChildren: './pages/faq-details/faq-details.module#FaqDetailsPageModule' },
  { path: 'faq/:id', loadChildren: './pages/faq-details/faq-details.module#FaqDetailsPageModule' },
  { path: 'kontakt', loadChildren: './kontakt/kontakt.module#KontaktPageModule'},
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then( m => m.TabsPageModule)
  },
  {
    path: 'om',
    loadChildren: () => import('./om/om.module').then( m => m.OmPageModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
